<template>
  <DynamicForm
    :initial-values="{
      title: reminder?.title,
      description: reminder?.description,
      date: parseStringISO(reminder?.date),
    }"
    :loading="loading"
    :schema="formSchema"
    ref="form"
    @submit="onSubmit"
  >
    <template #extra-buttons>
      <Button
        v-if="reminder"
        icon="pi pi-trash"
        label="Delete"
        outlined
        severity="danger"
        @click="confirm.require({
          accept: objectDelete,
          acceptProps: {
            label: 'Delete',
            severity: 'danger'
          },
          header: 'Delete reminder',
          message: 'Are you sure you want to delete this reminder?',
          rejectProps: {
            label: 'Cancel',
            severity: 'secondary',
            outlined: true
          }
        })"
      />
    </template>
  </DynamicForm>
</template>

<script lang="ts" setup>
import type {DynamicDialogInstance} from 'primevue/dynamicdialogoptions'
import type DynamicForm from '~/components/DynamicForm.vue'

const confirm = useConfirm()
const contentType = ref<ContentType>()
const dialogRef = inject<ComputedRef<DynamicDialogInstance>>('dialogRef')
const form = ref<typeof DynamicForm>()
const formSchema = {
  fields: [
    {
      as: 'InputText',
      label: 'Title',
      name: 'title',
      rules: yup.string().required().label('Title')
    },
    {
      as: 'Textarea',
      autoResize: true,
      label: 'Description',
      name: 'description',
      rules: yup.string().nullable().label('Description')
    },
    {
      as: 'DatePicker',
      hourFormat: '24',
      label: 'Date',
      name: 'date',
      rules: yup.date().nullable().label('Date'),
      showTime: true,
    }
  ]
}
const lead = ref<Lead>()
const loading = ref<boolean>(false)
const reminder = ref<Reminder>()
const toast = useToast()

onBeforeMount(() => {
  if (dialogRef?.value?.data?.contentType) {
    contentType.value = dialogRef.value.data.contentType
  }

  if (dialogRef?.value?.data?.lead) {
    lead.value = dialogRef.value.data.lead
  }

  if (dialogRef?.value?.data?.reminder) {
    reminder.value = dialogRef.value.data.reminder
  }
})

const objectDelete = async () => {
  if (!reminder.value) return
  loading.value = true
  const { $api } = useNuxtApp()
  await $api<EmptyResponse>(`/reminders/${reminder.value.id}/`, {
    method: 'DELETE'
  })
      .then(() => dialogRef?.value.close())
      .catch(error => errorsToToast(error))
      .finally(() => loading.value = false)
}

const objectId = computed<number|undefined>(() => {
  if (lead.value) return lead.value.id
})

const onSubmit = async (values: ReminderInput) => {
  loading.value = true

  const url = reminder.value ? `/reminders/${reminder.value.id}/` : '/reminders/'

  const { $api } = useNuxtApp()
  await $api<Reminder>(url, {
    method: reminder.value ? 'PUT' : 'POST',
    body: JSON.stringify({
      ...values,
      content_type: contentType.value ?? reminder.value?.content_type,
      object_id: objectId.value ?? reminder.value?.object_id,
    })
  })
    .then(() => dialogRef?.value?.close())
    .catch(error => form.value?.setErrors(error.data))
    .finally(() => loading.value = false)
}
</script>
